<script>
import Modal from "@/components/modals/modal-form.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import CustomerShow from "@/views/pages/customers/show/show.vue";

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        discountCode: Object,
    },
    data() {
        return {
            showDiscountCodeCustomersModalId: 'discount-code-customers-show-modal',
            table: {
                params: {
                },
                fields: [
                    { key: "id", label: "Cod", sortable: true },
                    { key: "pivot.created_at", label: "Fecha", sortable: true },
                    { key: "name", label: "Nombre", sortable: true },
                    { key: "identification_type.prefix", label: "Tipo de identificación", sortable: false },
                    { key: "identification", label: "Identificación", sortable: false },
                    { key: "user.email", label: "Email", sortable: false },
                ],
                formatted_fields: [
                    'pivot.created_at',
                ]
            },
            showSelectedItemId: null
        }
    },
    components: {
        Modal,
        CustomTable,
        CustomerShow
    },
    computed: {
        modalTilte(){
            return `Titulares que han usado el código #${(this?.discountCode?.code || '')}`;
        },   
        tableUrl() {
            return `/promotion-discount-codes/${this?.discountCode?.id}/customers`;
        }     
    },
    methods: {        
        showCustomerViewModal(item) {
            this.showSelectedItemId = item?.id || null;
            this.$bvModal.show(this.showDiscountCodeCustomersModalId);
        },
        shown() {
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        onSuccess(){            
            this.$refs.discountCodeCustomersTable.refresh();
        },
        formatter() {
        },
    }
};
</script>

<template>
    <Modal :title="modalTilte" :id="modalId" @cancel="cancel" @shown="shown" :size="'extra-xl'" :showConfirmButton="false" :label-cancel-btn="'Cerrar'">
        <div class="card h-100">
            <div class="card-body">
                <CustomerShow :modal-id="showDiscountCodeCustomersModalId" :id="showSelectedItemId"></CustomerShow>
                <CustomTable
                    v-if="discountCode"
                    ref="discountCodeCustomersTable"
                    :url="tableUrl"
                    :fields="table.fields"
                    :formatted-fields="table.formatted_fields"
                    :formatter="formatter"
                    :params="table.params"
                    :show-view="true"
                    @show="showCustomerViewModal"
                    :show-edit="false"
                    :showSoftDelete="false"
                >
                <template #cell-pivot.created_at="{ item }">
                    <span>{{ item?.pivot?.created_at | formatDate}}</span>
                </template>
            </CustomTable>
            </div>
        </div>
    </Modal> 
</template>