<script>
import api from '@/helpers/apirest/api';
import Vue from "vue";

/**
 * Advanced table component
 */
export default {
    components: {},
    emits: ['edit', 'changeStatus'],
    props: {
        url: String,
        containerTableClass: String,
        fields: {
            type: Array,
            default() {
                return []
            }
        },
        showInputSearch: {
            type: Boolean,
            default: true
        },
        showEdit: {
            type: Boolean,
            default: true
        },
        showView: {
            type: Boolean,
            default: false
        },
        showChangeStatus: {
            type: Boolean,
            default: false
        },
        showSoftDelete: {
            type: Boolean,
            default: true
        },
        showHardDelete: {
            type: Boolean,
            default: false
        },
        showActions: {
            type: Boolean,
            default: true
        },
        showAutoRefresh: {
            type: Boolean,
            default: false
        },
        autoHideOnFilter: {
            type: Boolean,
            default: false
        },
        params: {
            type: Object,
            default() {
                return {};
            }
        },
        formatter: {
            type: Function,
            default: () => { }
        },
        formattedFields: {
            type: Array,
            default() {
                return []
            }
        },
        filters2: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    data() {
        return {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "",
            sortDesc: false,
            isBusy: false,
            items: [], // Asegúrate de que `items` esté definido aquí
            tableFields: [
                ...this.fields,
                (this.showActions) ? { key: "actions", label: "Acciones", sortable: false, tdClass: "custom-table-action" } : null
            ],
            autorefresh: false,
            refreshSeconds: 5,
            interval: null,
            filters: { ...this.filters2 },
            isCollapsed: true
        };
    },
    computed: {
        hasFiltersSlot() {
            return !!this.$slots.filters || !!this.$scopedSlots.filters;
        },
        hasHeaderActionsSlot() {
            return !!this.$slots['header-actions'] || !!this.$scopedSlots['header-actions'];
        }
    },
    mounted() {
        this.refresh(); // Carga inicial de datos
    },
    methods: {
        async loadData(ctx) {
            this.isBusy = true;
            try {
                let sort = null;
                if (ctx?.sortBy != '') {
                    sort = (ctx?.sortDesc) ? 'desc' : 'asc';
                }

                const response = await api.get({
                    url: this.url,
                    params: {
                        ...this.params,
                        ...this.filters,
                        page: ctx?.currentPage,
                        per_page: ctx?.perPage,
                        filter: ctx?.filter || null,
                        sort_by: ctx?.sortBy || null,
                        sort: sort,
                    }
                });

                const data = response?.data?.data;
                const meta = response?.data?.meta;

                this.totalRows = meta?.total;

                if (this.showSoftDelete && data.length > 0 && data[0].deleted_at !== undefined) {
                    data.map(item => {
                        item.soft_delete_status = (item?.deleted_at) ? 0 : 1;
                    });
                } else {
                    this.showSoftDelete = false;
                }

                this.items = data; // Actualiza los items de la tabla
                this.isBusy = false;
                return data;
            } catch (error) {
                this.isBusy = false;
                return [];
            }
        },
        edit(row) {
            this.$emit("edit", row?.item);
        },
        show(row) {
            this.$emit("show", row?.item);
        },
        refresh() {
            this.loadData({
                currentPage: this.currentPage,
                perPage: this.perPage,
                filter: this.filter,
                sortBy: this.sortBy,
                sortDesc: this.sortDesc
            });
        },
        async changeStatus(item) {
            await api.patch({
                url: `${this.url}/${item?.id}/change-status`
            });

            this.refresh();
        },
        async softDeleteRow(item) {
            if (!item?.deleted_at) {
                await api.delete({
                    url: `${this.url}`,
                    id: item?.id
                });
            } else {
                await api.post({
                    url: `${this.url}/${item?.id}/restore`
                });
            }

            this.refresh();
        },
        async hardDeleteRow(item) {
            try {
                await Vue.swal({
                    title: "Eliminar este registro?",
                    icon: "info",
                    html: "Esta seguro que desea eliminar este registro?",
                    showCloseButton: false,
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: 'Sí',
                    cancelButtonText: 'Cancelar',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        return api.delete({
                            url: `${this.url}`,
                            id: item?.id
                        }).then(() => {
                            this.refresh();
                        });
                    },
                });
            } catch (error) {
                console.log(error);
            }
        },
        changeAutoRefresh() {
            if (this.interval) {
                clearInterval(this.interval);
            }

            if (this.autorefresh) {
                const self = this;
                const time = (this.refreshSeconds * 1000);
                this.interval = setInterval(function () {
                    self.refresh();
                }, time);
            }
        },
        applyFilters() {
            if(this.autoHideOnFilter){
                this.$root.$emit('bv::toggle::collapse', 'accordion-search-bar');
            }
            this.refresh();            
        }
    },
    watch: {
        filter() {
            this.currentPage = 1;
            this.refresh();
        },
        sortBy() {
            this.currentPage = 1;
            this.refresh();
        },
        sortDesc() {
            this.currentPage = 1;
            this.refresh();
        },
        currentPage() {
            this.refresh();
        },
        perPage() {
            this.currentPage = 1;
            this.refresh();
        },
        refreshSeconds() {
            this.changeAutoRefresh();
        }
    }
};
</script>

<template>
    <div>
        <div class="row mt-4">
            <!-- Filters -->
            <div class="col-sm-12 mb-4" v-if="hasFiltersSlot || hasHeaderActionsSlot">

                <b-card no-body class="shadow-none" >
                    <b-card-header header-tag="header" role="tab">
                        <h4 class="card-title custom-table-search-bar-header">
                            <a v-b-toggle.accordion-search-bar class="text-dark" href="javascript: void(0);">
                                <span v-if="hasFiltersSlot">
                                    Filtros
                                    <i class="mdi" :class="isCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up'"></i>
                                </span>
                            </a>
                            <slot name="header-actions" :filters="filters"></slot>
                        </h4>
                    </b-card-header>
                    <b-collapse id="accordion-search-bar" accordion="my-accordion" role="tabpanel"
                        @show="isCollapsed = false" @hide="isCollapsed = true">
                        <b-card-body>
                            <b-card-text>
                                <form @submit.prevent="applyFilters">
                                    <slot name="filters" :filters="filters" :loadData="loadData"></slot>
                                    <div class="row" v-if="hasFiltersSlot">
                                        <div class="col-12">
                                            <button type="submit" class="btn btn-outline-secondary btn-sm">
                                                Aplicar filtros
                                            </button>
                                            <hr class="my-4" />
                                        </div>
                                    </div>
                                </form>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>

            </div>
            <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                        <div class="d-inline-flex align-items-center">
                            Mostrar&nbsp;
                            <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;registros
                        </div>
                        <span class="ml-2">({{ totalRows }} {{ (totalRows == 1) ? 'resultado' : 'resultados' }})</span>
                    </label>
                </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6" v-if="showInputSearch">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                        Buscar:
                        <b-form-input debounce="500" v-model="filter" type="search" placeholder="Ingresa un texto..."
                            class="form-control form-control-sm ml-2"></b-form-input>
                    </label>
                </div>
            </div>
            <!-- End search -->
        </div>
        <!-- Table -->
        <div class="table-responsive mb-0 custom-table" :class="containerTableClass">
            <b-table ref="table" :items="items" :fields="tableFields" responsive="sm" :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc" :busy.sync="isBusy"> <!--:filter="filter" -->
                <template v-for="(column, index) in formattedFields" v-slot:[`cell(${column})`]="{ item }">
                    <slot :name="`cell-${column}`" :item="item">
                        <!-- Si no hay slot, utiliza el formateador por defecto -->
                        <span v-html="formatter(column, item)" :key="index"></span>
                    </slot>
                    <!-- <span v-html="formatter(column, item)" :key="index"></span> -->
                </template>

                <template #cell(actions)="row" v-if="showActions">
                    <div>
                        <slot name="before-custom-actions" v-bind:row="row"></slot>

                        <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover title="Ver detalle"
                            v-if="showView" @click="show(row)">
                            <i class="ri-eye-fill font-size-18"></i>
                        </a>

                        <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover title="Editar"
                            v-if="showEdit" @click="edit(row)">
                            <i class="mdi mdi-pencil font-size-18"></i>
                        </a>

                        <b-form-checkbox v-if="showChangeStatus" v-model="row.item.status" switch class="mb-1" value="1"
                            @change="changeStatus(row.item)"></b-form-checkbox>

                        <b-form-checkbox v-if="showSoftDelete" v-model="row.item.soft_delete_status" switch class="mb-1"
                            value="1" @change="softDeleteRow(row.item)"></b-form-checkbox>

                        <slot name="after-custom-actions" v-bind:row="row"></slot>
                        <a v-if="showHardDelete" href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                            title="Eliminar" @click="hardDeleteRow(row.item)">
                            <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                    </div>
                </template>
            </b-table>
            <div v-if="isBusy" class="text-center text-primary my-2 table-spinner">
                <b-spinner class="align-middle"></b-spinner>
            </div>
        </div>
        <div class="row">
            <div class="col mt-2">
                <div class="float-left autorefresh" v-if="showAutoRefresh">
                    <span class="mr-1">Auto refrescar </span>
                    <b-form-checkbox v-model="autorefresh" switch class="mb-1" value="1"
                        @change="changeAutoRefresh()"></b-form-checkbox>
                    <b-form-input debounce="500" v-if="autorefresh" v-model="refreshSeconds" type="number"
                        placeholder="" class="form-control form-control-sm ml-2"></b-form-input>
                </div>
                <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>