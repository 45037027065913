<template>
    <div>
        <div class="input-group">
            <input :type="passwordFieldType" class="form-control" v-model="internalValue" :id="id"
                :placeholder="placeholder" :class="{ 'is-invalid': validationName?.$error }"
                @input="validatePassword" />
            <div class="input-group-append">
                <div class="input-group-text">
                    <span class="mdi font-size-18 pointer" :class="passwordIconClass" :title="passwordIconTitle"
                        v-b-tooltip.hover @click="togglePasswordVisibility"></span>
                </div>
            </div>
        </div>
        <!-- Rules List -->
        <ul v-if="enableValidation && internalValue" class="mt-2">
            <li v-for="(rule, index) in rules" :key="index" :style="{ color: rule.valid ? 'green' : 'red' }">
                {{ rule.message }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "PasswordField",
    props: {
        value: {
            type: String,
            default: "",
        },
        validationName: Object,
        id: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: "Enter your password",
        },
        enableValidation: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            passwordFieldType: "password",
            rules: [
                { regex: /.{8,}/, message: "Al menos 8 caracteres", valid: false },
                { regex: /[A-Z]/, message: "Una letra mayúscula", valid: false },
                { regex: /[a-z]/, message: "Una letra minúscula", valid: false },
                { regex: /\d/, message: "Un número", valid: false },
                { regex: /[@$!%*?&#+]/, message: "Un carácter especial", valid: false },
            ],
        };
    },
    computed: {
        internalValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        passwordIconClass() {
            return this.passwordFieldType === "password" ? "mdi-eye" : "mdi-eye-off";
        },
        passwordIconTitle() {
            return this.passwordFieldType === "password" ? "Mostrar" : "Ocultar";
        },
    },
    methods: {
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
        },
        validatePassword() {
            if (this.enableValidation) {
                this.rules = this.rules.map((rule) => ({
                    ...rule,
                    valid: rule.regex.test(this.internalValue),
                }));

                // Emit event to parent with the validation status
                const isValid = this.internalValue ? this.rules.every((rule) => rule.valid) : true;
                this.$emit("validation-status", isValid);
            }
        },
    },
    watch: {
        value(newValue) {
            this.internalValue = newValue;
            if (this.enableValidation) {
                this.validatePassword();
            }
        },
    },
};
</script>