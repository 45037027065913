<script>
import { required, sameAs } from "vuelidate/lib/validators";
import api from '@/helpers/apirest/api';
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";
import ConfirmButton from "@/components/buttons/confirm-button.vue";
import PasswordField from "@/components/forms/password-field.vue";

import {
  authMethods
} from "@/state/helpers";

const initialState = () => {
    return {
        submitted: false,
        collaborator: {
            user_id: null,
            population_id: null,
            business_name: null,
            identification: null,
            contact_person: null,
            role: null,
            address: null,
            postcode: null,
            email: null,
            phone: null,
            mobile: null,
            status: 'pending',
            user: {
                name: null,
                email: null,
                password: null,
                repassword: null,
            }
        },
        isPasswordValid: false,
    };
}

export default {
    emits: ['onSuccess'],
    props: {
    },
    data() {
        return initialState();
    },
    components: {
        SelectAutocompleteField,
        ConfirmButton,
        PasswordField
    },
    computed: {
    },
    validations: {
        collaborator: {
            user_id: { },
            population: { },
            business_name: { required },
            identification: { required },
            contact_person: { required },
            role: { required },
            address: { required },
            postcode: { required },
            email: { required },
            phone: { },
            mobile: { required },
            status: { },
            user: {
                name: { required },
                email: { required },
                password: { required },
                repassword: { required, sameAs: sameAs('password') },
            }
        }
    },
    methods: {
        ...authMethods,
        getCollaboratorWrapper(collaborator) {
            if(!collaborator?.user) {
                collaborator.user = initialState().collaborator.user;
            }
            return collaborator;
        },
        handlePasswordValidation(isValid) {
            this.isPasswordValid = isValid;
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (!this.isPasswordValid || this.$v.$invalid) {
                return;
            }

            const data = this.collaborator;
            data.population_id = this.collaborator?.population?.id;

            await api.save({
                url: 'collaborators/register',
                data: data,
                config: {
                    withLoading: true
                }
            });

            await this.login({
                email: this.collaborator.user.email,
                password: this.collaborator.user.password
            });

            this.$router.push(
                this.$route.query.redirectFrom || { name: "home" }
            );
        },
        cancel() {
            if (window.history.length > 1) {
                this.$router.go(-1);
            } else {
                this.$router.push('/login');
            }
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
    }
};
</script>

<template>
    <form @submit.prevent="confirm">
        <div class="row">
            <div class="col-12 mb-2">
                <h4 class="card-title">Datos básicos</h4>
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label for="business_name">Razón social</label>
                <input id="business_name" v-model="collaborator.business_name" type="text" class="form-control" placeholder="Ingresa una razón social"
                    :class="{ 'is-invalid': $v.collaborator.business_name.$error && submitted }" />
                <div v-if="!$v.collaborator.business_name.required && submitted" class="invalid-feedback">Razón social es requerido</div>
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label for="identification">Identificación(Nif/Cif)</label>
                <input id="identification" v-model="collaborator.identification" type="text" class="form-control"
                    :class="{ 'is-invalid': $v.collaborator.identification.$error && submitted }" />
                <div v-if="!$v.collaborator.identification.required && submitted" class="invalid-feedback">Identificación(Nif/Cif) es requerido</div>
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label for="contact_person">Persona de contacto</label>
                <input id="contact_person" v-model="collaborator.contact_person" type="text" class="form-control" placeholder="Ingresa una persona de contacto"
                    :class="{ 'is-invalid': $v.collaborator.contact_person.$error && submitted }" />
                <div v-if="!$v.collaborator.contact_person.required && submitted" class="invalid-feedback">Persona de contacto es requerido</div>
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label for="role">Rol de la persona de contacto</label>
                <input id="role" v-model="collaborator.role" type="text" class="form-control" placeholder="Ingresa un rol de la persona"
                    :class="{ 'is-invalid': $v.collaborator.role.$error && submitted }" />
                <div v-if="!$v.collaborator.role.required && submitted" class="invalid-feedback">Rol de la persona es requerido</div>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-12 mb-2">
                <h4 class="card-title">Datos de dirección y contacto</h4>
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label>Población</label>
                <SelectAutocompleteField
                    :url="`/populations`"
                    :multiple="false"
                    :placeholder="'Busca una población'"
                    :label="'fullname'"
                    v-model="collaborator.population"
                    :class="{ 'is-invalid': $v.collaborator.population.$error && submitted }"
                ></SelectAutocompleteField>
                <div v-if="!$v.collaborator.population.required && submitted" class="invalid-feedback">Población es requerida</div>
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label for="address">Dirección</label>
                <input id="address" v-model="collaborator.address" type="text" class="form-control" placeholder="Ingresa una dirección"
                    :class="{ 'is-invalid': $v.collaborator.address.$error && submitted }" />
                <div v-if="!$v.collaborator.address.required && submitted" class="invalid-feedback">Dirección es requerida</div>
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label for="postcode">Código postal</label>
                <input id="postcode" v-model="collaborator.postcode" type="text" class="form-control" placeholder="Ingresa un código postal"
                    :class="{ 'is-invalid': $v.collaborator.postcode.$error && submitted }" />
                <div v-if="!$v.collaborator.postcode.required && submitted" class="invalid-feedback">Código postal es requerido</div>
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label for="mobile">Teléfono Móvil</label>
                <input id="mobile" v-model="collaborator.mobile" type="text" class="form-control" placeholder="Ingresa un teléfono móvil"
                    :class="{ 'is-invalid': $v.collaborator.mobile.$error && submitted }" />
                <div v-if="!$v.collaborator.mobile.required && submitted" class="invalid-feedback">Teléfono Móvil es requerido</div>
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label for="phone">Teléfono</label>
                <input id="phone" v-model="collaborator.phone" type="text" class="form-control" placeholder="Ingresa un teléfono"
                    :class="{ 'is-invalid': $v.collaborator.phone.$error && submitted }" />
                <div v-if="!$v.collaborator.phone.required && submitted" class="invalid-feedback">Teléfono es requerido</div>
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label for="email">Email de la empresa</label>
                <input id="email" v-model="collaborator.email" type="text" class="form-control" placeholder="Ingresa un email"
                    :class="{ 'is-invalid': $v.collaborator.email.$error && submitted }" />
                <div v-if="!$v.collaborator.email.required && submitted" class="invalid-feedback">Email es requerido</div>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-12 mb-2">
                <h4 class="card-title">Datos de inicio de sesión</h4>
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label for="user-name">Nombre</label>
                <input id="user-name" v-model="collaborator.user.name" type="text" class="form-control" placeholder="Ingresa un nombre"
                    :class="{ 'is-invalid': $v.collaborator.user.name.$error && submitted }" />
                <div v-if="!$v.collaborator.user.name.required && submitted" class="invalid-feedback">Nombre es requerido</div>
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label for="user-email">Email de acceso</label>
                <input id="user-email" v-model="collaborator.user.email" type="text" class="form-control" placeholder="Ingresa un email"
                    :class="{ 'is-invalid': $v.collaborator.user.email.$error && submitted }" />
                <div v-if="!$v.collaborator.email.required && submitted" class="invalid-feedback">Email es requerido</div>
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label for="password">Contraseña</label>
                <PasswordField
                    v-model="collaborator.user.password"
                    :validationName="$v.collaborator.user.password"
                    placeholder="Ingresa una contraseña"
                    :enable-validation="true"
                    @validation-status="handlePasswordValidation"
                ></PasswordField>
            </div>
            <div class="form-group col-lg-6 col-md-12">
                <label for="repassword">Cconfirmar contraseña</label>
                <input
                    v-model="collaborator.user.repassword"
                    type="password"
                    class="form-control"
                    id="repassword"
                    placeholder="EIngresa nuevamente tu nueva contraseña"
                    :class="{ 'is-invalid': submitted && $v.collaborator.user.repassword.$error }"
                />
                <div
                    v-if="submitted && $v.collaborator.user.repassword.$error"
                    class="invalid-feedback"
                >
                    <div v-if="!$v.collaborator.user.repassword.required">Requerido</div>
                    <div v-else-if="!$v.collaborator.user.repassword.sameAs">Contraseñas no coinciden</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-4 text-center">
                <b-button
                    variant="secondary"
                    class="mr-2"
                    @click="cancel"
                >
                    Cancelar
                </b-button>
                <ConfirmButton label="Registrar"></ConfirmButton>
            </div>
        </div>
    </form>
</template>