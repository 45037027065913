<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import PromotionForm from "./forms/promotion-form.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import AddButton from "@/components/buttons/add-button.vue";
import Status from "@/helpers/general/status";
import PromotionDiscountCodesShow from "./show/promotion-discount-codes-show.vue";
import CollaboratorShow from "@/views/pages/collaborators/show/show";
import FeaturedIcon from '@/components/widgets/featured-icon.vue';

/**
 * Promotions Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        CustomTable,
        PromotionForm,
        AddButton,
        PromotionDiscountCodesShow,
        CollaboratorShow,
        FeaturedIcon
    },
    data() {
        return {
            showModalId: 'promotion-show-modal',
            showDiscountCodeModalId: 'promotion-show-modal',
            showCollaboratorModalId: 'promotion-collaborator-show-modal',
            formModalId: 'promotion-form-modal',
            pageHeader: {
                title: "Promociones",
                breadcrumbs: [
                    {
                        text: "Promociones",
                        href: "/promotions",
                        active: true
                    }
                ],
            },
            table: {
                url: '/promotions',
                params: {
                    'with_trashed': 1,
                    'status': 'all'
                },
                fields: [
                    { key: "id", label: "Cod", sortable: true },
                    { key: "created_at", label: "Fecha de creación", sortable: true },
                    { key: "title", label: "Titulo", sortable: true },
                    { key: "collaborator.business_name", label: "Colaborador", sortable: false },
                    { key: "creator.name", label: "Creado por", sortable: false },
                    { key: "approver.name", label: "Aprobado por", sortable: false },
                    { key: "status", label: "Estado", sortable: false },
                    { key: "featured", label: "Destacada", sortable: false },
                ],
                formatted_fields: [
                    'created_at',
                    'title',
                    'status',
                    'collaborator.business_name',
                    'featured',
                ]
            },
            formSelectedItemId: null,
            showSelectedItemId: null,
            showSelectedPromotion: null,
            showSelectedCollaboratorItem: null,
        };
    },
    methods: {
        showViewModal(item) {
            this.showSelectedItemId = item?.id || null;
            this.$bvModal.show(this.showModalId);
        },
        showFormModal(item) {
            this.formSelectedItemId = item?.id || null;
            this.$bvModal.show(this.formModalId);
        },
        showDiscountCodesModal(item) {
            this.showSelectedPromotion = item || null;
            this.$bvModal.show(this.showDiscountCodeModalId);
        },
        showViewCollaboratorModal(item) {
            this.showSelectedCollaboratorItem = item?.collaborator_id || null;
            this.$bvModal.show(this.showCollaboratorModalId);
        },
        onSuccess() {
            this.$refs.promotionTable.refresh();
        },
        formatter(field, item) {
            switch (field) {
                case 'status':
                    return Status.getStatus({ itemStatus: item?.status, type: 'promotion_status', html: true });
                default:
                    return '';
            }
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <CollaboratorShow :modal-id="showCollaboratorModalId" :id="showSelectedCollaboratorItem"></CollaboratorShow>
                        <PromotionForm :modal-id="formModalId" :id="formSelectedItemId" @onSuccess="onSuccess"></PromotionForm>
                        <PromotionDiscountCodesShow :modal-id="showDiscountCodeModalId" :promotion="showSelectedPromotion"></PromotionDiscountCodesShow>
                        <CustomTable :url="table.url" :fields="table.fields" :params="table.params" ref="promotionTable"
                            :formatted-fields="table.formatted_fields" :formatter="formatter"
                            :show-view="false" :show-edit="true" :showSoftDelete="false" @show="showViewModal"
                            @edit="showFormModal" :show-modal-id="showModalId" >
                            <template v-slot:header-actions>
                                <AddButton @click="showFormModal"
                                    v-has-permission="{ permission: 'edit_user', action: 'disable' }"></AddButton>
                            </template>
                            <!-- <template v-slot:filters="{ filters }">
                <div class="row">
                    <div class="form-group col-12 col-lg-4 col-sm-6">
                        <label for="promotion_type">Tipo de cliente</label>
                        <input id="promotion_type" v-model="filters.promotion_type" type="text" class="form-control" placeholder="Ingresa un texto"/>
                    </div>
                </div> 
              </template> -->
                            <template #cell-created_at="{ item }">
                                {{ item?.created_at | formatDate }}
                            </template>
                            <template #cell-title="{ item }">
                                <div :title="item?.title" v-b-tooltip.hover>{{ item?.title | truncateText(60) }}</div>
                            </template>
                            <template #cell-collaborator.business_name="{ item }">
                                <a href="javascript:void(0);" class="mr-1 text-primary" v-b-tooltip.hover title="Ver colaborador"
                                    v-if="item?.collaborator_id" @click="showViewCollaboratorModal(item)">
                                    <i class="ri-eye-fill font-size-18"></i>
                                </a>
                                <span>{{ item?.collaborator?.business_name }}</span>
                            </template>
                            <template #cell-featured="{ item }">
                                <featured-icon :featured="item?.featured"></featured-icon>
                            </template>
                            <template v-slot:before-custom-actions="slotProps">
                                <a href="javascript:void(0);" class="mr-3 text-primary" title="Gestionar códigos de descuento"
                                    @click="showDiscountCodesModal(slotProps.row?.item)">
                                    <i class="mdi mdi-brightness-percent font-size-18"></i>
                                </a>
                            </template>
                        </CustomTable>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>