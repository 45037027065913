<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import CollaboratorShow from "./show/show.vue";
import CollaboratorForm from "./forms/collaborator-form.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import AddButton from "@/components/buttons/add-button.vue";

/**
 * Collaborators Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    CollaboratorShow,
    CustomTable,
    CollaboratorForm,
    AddButton
  },
  data() {
    return {
      showModalId:'collaborator-show-modal',
      formModalId:'collaborator-form-modal',
      pageHeader:{
        title: "Colaboradores",
        breadcrumbs: [
          {
            text: "Colaboradores",
            href: "/collaborators",
            active: true
          }
        ],
      },
      table:{
        url:'/collaborators',
        params: {
          'with_trashed': 1,
        },
        fields:[
          { key: "id", label: "Cod", sortable: true },
          { key: "business_name", label: "Nombre", sortable: true },
          { key: "identification", label: "Identificación", sortable: false },
          { key: "email", label: "Email", sortable: false },
          { key: "created_at", label: "Fecha de creación", sortable: true },
        ],
      },
      formSelectedItemId:null,   
      showSelectedItemId:null,
      clone:false
    };
  },
  methods: {
    showViewModal(item) {
      this.showSelectedItemId = item?.id || null;
      this.$bvModal.show(this.showModalId);
    },
    showFormModal(item) {
      this.formSelectedItemId = item?.id || null;
      this.$bvModal.show(this.formModalId);
    },
    onSuccess(){
      this.$refs.collaboratorTable.refresh();
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <CollaboratorShow :modal-id="showModalId" :id="showSelectedItemId"></CollaboratorShow>
            <CollaboratorForm :modal-id="formModalId" :id="formSelectedItemId" @onSuccess="onSuccess"></CollaboratorForm>
            <CustomTable
              :url="table.url"
              :fields="table.fields"
              :params="table.params"
              ref="collaboratorTable"
              :show-view="true"
              :show-edit="true"
              :showSoftDelete="false"
              @show="showViewModal"
              @edit="showFormModal"
              :show-modal-id="showModalId"
            >
              <template v-slot:header-actions>
                <AddButton @click="showFormModal"></AddButton>
              </template>
              <!-- <template v-slot:filters="{ filters }">
                <div class="row">
                    <div class="form-group col-12 col-lg-4 col-sm-6">
                        <label for="collaborator_type">Tipo de colaborador</label>
                        <input id="collaborator_type" v-model="filters.collaborator_type" type="text" class="form-control" placeholder="Ingresa un texto"/>
                    </div>
                </div> 
              </template> -->
            </CustomTable>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>