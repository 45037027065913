<script>
import EstablishmentForm from "@/views/pages/collaborators/forms/establishment-form.vue";
import AdvantageForm from "@/views/pages/collaborators/forms/advantage/advantage-form.vue";
export default {
    props: {
        collaborator: Object
    },
    emits: ['onSuccess'],
    components: {
        EstablishmentForm,
        AdvantageForm
    },
    data() {
        return {
            establishmentFormId: 'progress-establishment-form-id',
            advantageFormId: 'progress-advantage-form-id',
        };
    },
    computed: {
        progressPercentage() {
            if(this?.collaborator?.establishments_count == 0){
                return 33;
            }else if(this?.collaborator?.advantages_count == 0){
                return 66;
            }else{
                return 100;
            }
        },
    },
    methods: {
        showEstablishmentForm() {
            this.$bvModal.show(this.establishmentFormId);
        },
        showAdvantageForm() {
            this.$bvModal.show(this.advantageFormId);
        },
        onSuccess(response) {
            this.$emit("onSuccess", response?.data?.data);
        },
    },
};
</script>
<template>
    <div>
        <EstablishmentForm 
            :modal-id="establishmentFormId"
            :collaborator="collaborator"
            @onSuccess="onSuccess"
        ></EstablishmentForm>
        <AdvantageForm
            :modal-id="advantageFormId"
            :collaborator="collaborator"
            @onSuccess="onSuccess"
        ></AdvantageForm>
        <div class="progress progress-sm animated-progess" style="height: 5px">
            <div
                class="progress-bar bg-success"
                role="progressbar"
                :style="{ width: progressPercentage + '%' }"
                aria-valuenow="72"
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
        </div>
        <p>{{ progressPercentage }}% completado</p>
        <div class="text-center" v-if="collaborator?.establishments_count == 0">
            <p>¡Muy bien! Ya has ingresado tus datos básicos. Ahora es momento de crear tu primer establecimiento.</p>
            <a href="javascript:void(0);" class="btn btn-outline-success btn-sm waves-effect waves-light"
                @click="showEstablishmentForm()">
                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar establecimiento
            </a>
        </div>
        <div class="text-center" v-else-if="collaborator?.advantages_count == 0">
            <p>¡Ya casi estás listo! Crea tu primera ventaja para continuar.</p>
            <a href="javascript:void(0);" class="btn btn-outline-success btn-sm waves-effect waves-light"
                @click="showAdvantageForm()">
                <i class="mdi mdi-plus-circle font-size-18"></i> Agregar ventaja
            </a>
        </div>
        <div class="text-center" v-else>
            <p>¡Enhorabuena! Has completado todos los pasos. Ahora, uno de nuestros moderadores revisará la información y aprobará tu perfil en breve.</p>
        </div>
    </div>
</template>