<script>
import api from '@/helpers/apirest/api';
import Modal from "@/components/modals/modal-form.vue";
import Status from "@/helpers/general/status";
import EstablishmentsPartial from "@/views/pages/collaborators/show/partials/establishments-partial.vue";
import AdvantagesPartial from "@/views/pages/collaborators/show/partials/advantages-partial.vue";
import CollaboratorForm from "@/views/pages/collaborators/forms/collaborator-form.vue";
import BasicDataPartial from '@/views/pages/collaborators/show/partials/basic-data-partial.vue';
import PromotionsPartial from '@/views/pages/collaborators/show/partials/promotions-partial.vue';

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number,
        cardApplicationId: Number
    },
    data() {
        return {
            collaborator:null,
            showOrderModalId:'order-show-modal',
            formModalId:'collaborator-form-detail-modal',
            collaboratorIntegrationStatus: Status,
            showSelectedItemId:null,
            activeTab:null
        }
    },
    components: {
        Modal,
        EstablishmentsPartial,
        AdvantagesPartial,
        CollaboratorForm,
        BasicDataPartial,
        PromotionsPartial
    },
    computed: {
        modalTilte(){
            return `Detalle del colaborador #${(this.collaborator?.id || '')}`;
        },
        status(){
            return Status.getStatus({itemStatus: this?.collaborator?.status, type: 'collaborator_status', html: true});
        },
    },
    methods: {
        async getData(){
            if (this.id) {
                const response = await api.get({
                    url: `collaborators/${this.id}`,
                    config: {
                        withLoading: true
                    }
                });
                this.collaborator = response?.data?.data;
            }
        },
        shown() {
            this.getData();            
        },
        hidden() {
            this.collaborator = null;
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        showFormModal() {
            this.$bvModal.show(this.formModalId);
        },
        onSuccess(){            
            this.getData(); 
        },
    }
};
</script>

<template>
    <div>
        <Modal :title="modalTilte" :id="modalId" @cancel="cancel" @shown="shown" @hidden="hidden" :size="'extra-xl'" :showConfirmButton="false" :label-cancel-btn="'Cerrar'">
            <CollaboratorForm :modal-id="formModalId" :id="collaborator?.id" @onSuccess="onSuccess"></CollaboratorForm>
            <div class="row mb-4">
                <div class="col-xl-3 col-lg-4">
                    <div class="card h-100">
                        <div class="card-body">
                            <div class="row mb-4">
                                <div class="col-12 d-flex align-items-center flex-column justify-content-center">
                                    <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                        <template #loading>
                                            <b-skeleton type="avatar"></b-skeleton>
                                            <b-skeleton width="100%"></b-skeleton>
                                            <b-skeleton width="100%"></b-skeleton>                                            
                                        </template> 

                                        <b-dropdown no-flip right class="qlk-dropdown-sm" style="position: absolute; top: 0px; right: 10px" variant="light" size="sm">
                                            <template v-slot:button-content>
                                                <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                                            </template>
                                            <b-dropdown-item @click="showFormModal">Editar</b-dropdown-item>
                                        </b-dropdown>

                                        <div class="avatar-sm">
                                            <span class="avatar-title text-center text-uppercase rounded-circle bg-soft-primary text-primary">
                                            {{ collaborator?.business_name?.charAt(0) + collaborator?.business_name?.charAt(1) }}
                                            </span>
                                        </div> 
                                        <h4 class="mt-3 mb-0">{{ collaborator?.business_name }}</h4>
                                        <p class="text-muted font-size-14 mb-0">{{ collaborator?.identification }}</p>
                                    </b-skeleton-wrapper>
                                </div>
                            </div> 
                            <!-- <hr class="my-3" />  -->
                            <div class="row">
                                <div class="col-12">
                                    <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                        <template #loading>
                                            <b-skeleton-table
                                                :rows="4"
                                                :columns="2"
                                                :hide-header="true"
                                                :table-props="{ bordered: true, striped: true }"
                                            ></b-skeleton-table> 
                                        </template>
                                        <table class="table table-hover mb-0 table-centered">
                                            <tbody>
                                                <tr>
                                                    <th class="text-right nowrap">Estado</th>
                                                    <td v-html="status"></td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right nowrap">Contacto</th>
                                                    <td>
                                                        <span :title="collaborator?.contact_person">{{ collaborator?.contact_person | truncateText(20)}}</span>
                                                        <span v-if="collaborator?.role">
                                                            <br>({{ collaborator?.role }})
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right nowrap">Teléfono</th>
                                                    <td>{{ collaborator?.phone }}</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right nowrap">Móvil</th>
                                                    <td>{{ collaborator?.mobile }}</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right nowrap">Email</th>
                                                    <td>{{ collaborator?.email }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </b-skeleton-wrapper> 
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="col-xl-9 col-lg-8">
                    <div class="card h-100">
                        <div class="card-body">
                            <b-tabs v-model="activeTab" content-class="p-3 text-muted">
                                <b-tab class="border-0">
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">Datos básicos</span>
                                    </template>
                                    <b-card-text>
                                        <BasicDataPartial :collaborator="collaborator"></BasicDataPartial>
                                    </b-card-text>
                                </b-tab>
                                <b-tab class="border-0" lazy>
                                    <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Establecimientos</span>
                                    </template>
                                    <b-card-text>
                                        <EstablishmentsPartial :collaborator="collaborator"></EstablishmentsPartial>                                        
                                    </b-card-text>
                                </b-tab>
                                <b-tab class="border-0" lazy>
                                    <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Ventajas</span>
                                    </template>
                                    <b-card-text>
                                        <AdvantagesPartial :collaborator="collaborator"></AdvantagesPartial>
                                    </b-card-text>
                                </b-tab>    
                                <b-tab class="border-0" lazy>
                                    <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">Promociones</span>
                                    </template>
                                    <b-card-text>
                                        <PromotionsPartial :collaborator="collaborator"></PromotionsPartial>
                                    </b-card-text>
                                </b-tab>                           
                            </b-tabs>                      
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>    
</template>