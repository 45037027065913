import api from '@/helpers/apirest/api';

export const state = {
    permissions: null
}

export const mutations = {
    SET_PERMISSIONS(state, newValue) {
        state.permissions = newValue;
    },
}

export const getters = {
    hasPermission: (state) => (permission) => {
        if(state?.permissions?.roles?.includes('administrator')) return true;    
        if(state?.permissions?.permissions?.includes(permission)) return true;
    
        return false;
    },
    isCollaborator: (state) => () => {
        if(state?.permissions?.roles?.includes('collaborator')) return true;
        return false;
    },
    isAdmin: (state) => () => {
        if(state?.permissions?.roles?.includes('administrator')) return true;
        return false;
    },

};

export const actions = {

    async loadList({ commit, state }) {

        if(!state?.permissions){
            const response = await api.get({
                url: 'user/permissions'
            });
    
            const data = response?.data?.data;         
            commit('SET_PERMISSIONS', data);
        }        
    }
}