<template>
    <div class="custom-date-range-picker">
        <date-range-picker
            v-model="internalDateRange"
            class="qlk-date-range-picker"
            :auto-apply="true"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :showDropdowns="true"
            :opens="'right'"
            @update="emitUpdate"
        >
        </date-range-picker>
        <button type="button" v-if="showClearButton && internalDateRange.startDate" @click="clearDates" class="btn-clear" title="Limpiar">x</button>
    </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
    name: "DateRangeField",
    components: { DateRangePicker },
    props: {
        value: {
            type: Object,
            default: () => ({
                startDate: null,
                endDate: null,
            }),
        },
        showClearButton: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            internalDateRange: { ...this.value },
        };
    },
    watch: {
        value: {
            handler(newVal) {
                this.internalDateRange = { ...newVal };
            },
            deep: true,
        },
    },
    methods: {
        emitUpdate() {
            this.$emit("input", this.internalDateRange);
        },
        clearDates() {
            this.internalDateRange = {
                startDate: null,
                endDate: null,
            };
            this.emitUpdate();
        },
    },
};
</script>

<style scoped>
.custom-date-range-picker {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.btn-clear {
    position: absolute;
    background: transparent;
    border: none;
    padding: 0px;
    cursor: pointer;
    line-height: 38px;
    top: 0px;
    right: 10px;
    font-size: 16px;
    color: #505d69;
}
</style>