<script>
import api from '@/helpers/apirest/api';
import Modal from "@/components/modals/modal-form.vue";
import Status from "@/helpers/general/status";
import QlkFailedRequestBtn from '@/components/widgets/qlk-failed-request-btn.vue';
import QlkOrderIntegrationResyncBtn from '@/components/widgets/qlk-order-integration-resync-btn.vue';
import OrderIntegrationResponse from './partials/order-integration-response';

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return {
            order:null,
            orderFormModalId:'order-form-modal-show',
            orderIntegrationModalId:'order-integration-modal-show',
            orderIntegrationStatus: Status,
            selectedOrderIntegration:null
        }
    },
    components: {
        Modal,
        QlkFailedRequestBtn,
        QlkOrderIntegrationResyncBtn,
        OrderIntegrationResponse
    },
    computed: {
        modalTilte(){
            return `Detalles de Pedido #${(this.order?.external_id || '')}(${(this.order?.store?.name || '')})`;
        },
        getSourceDriverImg(){          
            return require(`@/assets/images/drivers/${this.order?.source_driver?.source_driver_type?.slug}.png`) ;
        },
        getTargetDriverImg(){          
            return require(`@/assets/images/drivers/${this.order?.target_driver?.target_driver_type?.slug}.png`) ;
        },
        status(){
            return Status.getStatus({itemStatus: this?.order?.status, type: 'orders', html: true});
        },
    },
    methods: {
        async getData(){
            if (this.id) {
                const response = await api.get({
                    url: `orders/${this.id}`,
                    config: {
                        withLoading: true
                    }
                });
                this.order = response?.data?.data;
            }
        },
        showOrderIntegrationModal(item) {
            this.selectedOrderIntegration = item || null;
            this.$bvModal.show(this.orderIntegrationModalId);
        },
        shown() {
            this.getData();            
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        onSuccess(){            
            this.getData(); 
        },
    }
};
</script>

<template>
    <div>
        <OrderIntegrationResponse :modal-id="orderIntegrationModalId" :order-integration="selectedOrderIntegration"></OrderIntegrationResponse>
        <Modal :title="modalTilte" :id="modalId" @cancel="cancel" @shown="shown" :size="'extra-xl'" :showConfirmButton="false" :label-cancel-btn="'Close'">
            <div class="row">
                <div class="col-lg-4 col-xs-12 col-md-6 mb-4">
                    <div class="card h-100">
                        <div class="card-body">                         
                            <h4 class="card-title mb-4">Datos generales</h4>
                            <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                <template #loading>
                                    <b-card>
                                        <b-skeleton width="75%"></b-skeleton>
                                        <b-skeleton width="50%"></b-skeleton>
                                        <b-skeleton width="85%"></b-skeleton>
                                    </b-card>
                                </template>
                                <div class="table-responsive">
                                    <table class="table table-hover mb-0 table-centered table-nowrap">
                                        <tbody>
                                        <tr>
                                            <th class="text-right">Fecha</th>
                                            <td>{{ order?.created_at }}</td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">Estado</th>
                                            <td><span v-html="status"></span></td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">Cliente</th>
                                            <td>{{ order?.customer?.name || 'No registra' }}</td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">Carné joven</th>
                                            <td>{{ order?.youth_card?.code || 'N/A' }}</td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">Carné joven externo</th>
                                            <td>{{ order?.external_youth_card_number || 'N/A' }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-skeleton-wrapper>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xs-12 col-md-6 mb-4">
                    <div class="card h-100">
                        <div class="card-body">                            
                            <h4 class="card-title mb-4">Facturación</h4>

                            <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                <template #loading>
                                    <b-card>
                                        <b-skeleton width="75%"></b-skeleton>
                                        <b-skeleton width="50%"></b-skeleton>
                                        <b-skeleton width="85%"></b-skeleton>
                                        <b-skeleton width="45%"></b-skeleton>
                                        <b-skeleton width="50%"></b-skeleton>
                                        <b-skeleton width="60%"></b-skeleton>
                                    </b-card>
                                </template>
                                <div class="mb-2">
                                    <h6>{{ order?.order_billing?.full_name }}</h6>
                                    <div v-if="order?.order_billing?.company">{{ order?.order_billing?.company }}</div>
                                    <div v-if="order?.order_billing?.address_1">{{ order?.order_billing?.address_1 }}</div>
                                    <div v-if="order?.order_billing?.address_2">{{ order?.order_billing?.address_2 }}</div>
                                    <div v-if="order?.order_billing?.postcode || order?.order_billing?.city">{{ order?.order_billing?.postcode }} {{ order?.order_billing?.city }}</div>
                                    <div v-if="order?.order_billing?.state">{{ order?.order_billing?.state }}</div>
                                    <div v-if="order?.order_billing?.country">{{ order?.order_billing?.country }}</div>
                                </div>
                                <div class="mb-2">
                                    <h6>Dirección de correo electrónico:</h6>
                                    <a :href="'mailto:' + order?.order_billing?.email">{{ order?.order_billing?.email }}</a>
                                </div>
                                <div>
                                    <h6>Teléfono:</h6>
                                    <a :href="'tel:' + order?.order_billing?.phone">{{ order?.order_billing?.phone }}</a>
                                </div>
                            </b-skeleton-wrapper>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xs-12 col-md-6 mb-4">
                    <div class="card h-100">
                        <div class="card-body">                            
                            <h4 class="card-title mb-4">Envío</h4>

                            <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                <template #loading>
                                    <b-card>
                                        <b-skeleton width="75%"></b-skeleton>
                                        <b-skeleton width="50%"></b-skeleton>
                                        <b-skeleton width="85%"></b-skeleton>
                                        <b-skeleton width="30%"></b-skeleton>
                                        <b-skeleton width="65%"></b-skeleton>
                                    </b-card>
                                </template>
                                <div class="mb-2" v-if="order?.order_shipping">
                                    <div class="mb-2">
                                        <h6>{{ order?.order_shipping?.full_name }}</h6>
                                        <div v-if="order?.order_shipping?.company">{{ order?.order_shipping?.company }}</div>
                                        <div v-if="order?.order_shipping?.address_1">{{ order?.order_shipping?.address_1 }}</div>
                                        <div v-if="order?.order_shipping?.address_2">{{ order?.order_shipping?.address_2 }}</div>
                                        <div v-if="order?.order_shipping?.postcode || order?.order_shipping?.city">{{ order?.order_shipping?.postcode }} {{ order?.order_shipping?.city }}</div>
                                        <div v-if="order?.order_shipping?.state">{{ order?.order_shipping?.state }}</div>
                                        <div v-if="order?.order_shipping?.country">{{ order?.order_shipping?.country }}</div>
                                    </div>
                                    <div>
                                        <h6>Teléfono:</h6>
                                        <a :href="'tel:' + order?.order_shipping?.phone">{{ order?.order_shipping?.phone }}</a>
                                    </div>
                                </div>
                                <div v-else>
                                    <i>Sin dirección de envío configurada.</i>
                                </div>
                            </b-skeleton-wrapper>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xs-12 col-md-6 mb-4">
                    <div class="card h-100">
                        <div class="card-body">                            
                            <h4 class="card-title mb-4">Productos</h4>
                            <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                <template #loading>
                                    <b-skeleton-table
                                        :rows="5"
                                        :columns="4"
                                        :table-props="{ bordered: true, striped: true }"
                                    ></b-skeleton-table>
                                </template>

                                <div class="table-responsive">
                                    <table class="table table-hover mb-0 table-centered">
                                        <thead>
                                            <tr>
                                                <th>Artículo</th>
                                                <th>Origen</th>
                                                <th class="text-right">Precio unidad</th>
                                                <th class="text-right">Dcto</th>
                                                <th class="text-right">Cantidad</th>
                                                <th class="text-right">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="item in order?.order_items" :key="item.id">
                                            <td>{{ item?.product?.name }}</td>
                                            <td>{{ item?.product?.integration?.name }}</td>
                                            <td class="text-right">{{ (item?.subtotal / item?.quantity) | currency }}</td>
                                            <td class="text-right">{{ ((item?.discount_total) / item?.quantity) | currency }}</td>
                                            <td class="text-right">{{ item?.quantity }}</td>
                                            <td class="text-right">
                                                <strong>{{ item?.total_with_tax | currency }}</strong>
                                                <span class="text-muted ml-1 text-small" v-if="item?.total_tax">(Imp: {{ item?.total_tax | currency }})</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan="5" class="text-right bg-light">Subtotal:</td>
                                                <td class="text-right bg-light">{{ order?.subtotal | currency }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="5" class="text-right bg-light">Cupón(es):</td>
                                                <td class="text-right bg-light"> -{{ order?.discount_total | currency }}</td>
                                            </tr>
                                            <tr v-for="rate in order?.order_tax_lines" :key="rate.id">
                                                <td colspan="5" class="text-right bg-light">{{ rate?.label }}:</td>
                                                <td class="text-right bg-light"> {{ rate?.tax_total | currency }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="5" class="text-right bg-light">Total:</td>
                                                <td class="text-right bg-light"><h5>{{ order?.total | currency }}</h5></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </b-skeleton-wrapper>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xs-12 col-md-6 mb-4">
                    <b-skeleton-wrapper :loading="$store.state.loading.loading">
                        <template #loading>
                            <div class="card">
                                <div class="card-body">
                                    <div class="media mb-4">
                                        <div class="media-body overflow-hidden">
                                            <b-skeleton width="45%"></b-skeleton>
                                            <b-skeleton width="35%"></b-skeleton>
                                        </div>
                                        <div class="text-primary">
                                            <b-skeleton type="avatar"></b-skeleton>
                                        </div>
                                    </div>
                                    <div>
                                        <b-skeleton width="25%"></b-skeleton>
                                        <b-skeleton width="65%"></b-skeleton>
                                    </div>
                                </div>
                                <div class="card-body border-top py-3">
                                    <div class="text-truncate d-flex gap-2">
                                        <b-skeleton width="35%"></b-skeleton>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="card" v-for="integration in order?.order_integrations" :key="integration.id">                            
                            <div class="card-body">
                                <div class="media mb-4">
                                    <div class="media-body overflow-hidden">
                                        <h4 class="card-title">{{ integration?.integration?.name }}</h4>
                                        <h5 class="mb-0">{{ integration?.total | currency }}</h5>
                                    </div>
                                    <div class="text-primary">
                                        <img :src="integration?.integration?.media?.url" :alt="integration?.integration?.name" class="rounded-circle avatar-sm" />
                                    </div>
                                    
                                </div>
                                <div>
                                    <h6>Identificador</h6>
                                    <p class="text-muted mb-0" v-if="integration?.external_id">{{ integration?.external_id }}</p>
                                    <p class="text-danger mb-0" v-else>Sin identificador</p>
                                </div>
                                <div v-if="integration?.integration?.slug == 'astun'">
                                    <h6>Localizador</h6>
                                    <p class="text-muted mb-0" v-if="integration?.data?.purchase?.voucher">{{ integration?.data?.purchase?.voucher }}</p>
                                    <p class="text-danger mb-0" v-else>Sin localizador</p>
                                </div>
                                <div v-if="integration?.data">
                                    <a href="javascript:void(0);" @click="showOrderIntegrationModal(integration)">Ver detalle</a>                                
                                </div>
                            </div>
                            <div class="card-body border-top py-3">
                                <div class="text-truncate d-flex gap-2">
                                    <span v-html="orderIntegrationStatus.getStatus({itemStatus: integration?.status, type: 'order_integration_status', html: true})"></span>
                                    <QlkFailedRequestBtn :id="integration?.failed_request_id"></QlkFailedRequestBtn>
                                    <QlkOrderIntegrationResyncBtn :orderIntegration="integration" @onSuccess="getData"></QlkOrderIntegrationResyncBtn>
                                </div>
                            </div>
                        </div>
                    </b-skeleton-wrapper>
                </div>
            </div>
        </Modal>
    </div>    
</template>