<script>
import Modal from "@/components/modals/modal-form.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import Status from "@/helpers/general/status";
import DiscountCodeCustomersShow from './discount-code-customers-show.vue';
import PromotionForm from "../forms/promotion-form.vue";
import AddButton from "@/components/buttons/add-button.vue";
import SelectField from '@/components/forms/select-field.vue';
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        promotion: Object,
        id: Number
    },
    data() {
        return {
            showDiscountCodeCustomerModalId: 'discount-code-customers-show-table-modal',
            formModalId: 'discount-code-promotion-form-modal',
            table: {
                params: {
                },
                fields: [
                    { key: "id", label: "Cod", sortable: false },                    
                    { key: "created_at", label: "Fecha de creación", sortable: false },
                    { key: "promotion_modality.title", label: "modalidad", sortable: false },
                    { key: "code", label: "Código", sortable: false },
                    { key: "customers_count", label: "Usos", sortable: false },                                      
                    { key: "status", label: "Estado", sortable: false },
                ],
                formatted_fields: [
                    'status',
                    'customers_count',
                    'promotion_modality.title',
                ],
                filters: {
                    promotion_modality_id: null,
                    code: null, 
                    used: null, 
                    created_at_range: {
                        startDate: null,
                        endDate: null
                    },
                }
            },
            showSelectedDiscountCodeItem: null,
            usedStatus: [
                { id: null, name: 'Todos' },
                { id: 1, name: 'Usados' },
                { id: 0, name: 'No usados' },
            ]
        }
    },
    components: {
        Modal,
        CustomTable,
        AddButton,
        PromotionForm,
        DiscountCodeCustomersShow,
        SelectField,
        DateRangePicker
    },
    computed: {
        modalTilte(){
            return `Códigos de descuento de la promoción #${(this?.promotion?.id || '')}`;
        },   
        tableUrl() {
            return `/promotions/${this.promotion?.id}/discount-codes`;
        }     
    },
    methods: {    
        showFormModal() {
            this.$bvModal.show(this.formModalId);
        },    
        showViewCustomerModal(item) {
            this.showSelectedDiscountCodeItem = item || null;
            this.$bvModal.show(this.showDiscountCodeCustomerModalId);
        },
        shown() {
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        onSuccess(){  
            this.$refs.promotionDiscountCodesTable.refresh();          
        },
        formatter(field, item) {
            switch (field) {
                case 'status':
                    return Status.getStatus({ itemStatus: item?.status, type: 'general', html: true });                
                case 'promotion_modality.title':
                    return item?.promotion_modality?.title || 'N/A';                
                default:
                    return '';
            }
        },
    }
};
</script>

<template>
    <Modal :title="modalTilte" :id="modalId" @cancel="cancel" @shown="shown" :size="'extra-xl'" :showConfirmButton="false" :label-cancel-btn="'Cerrar'">
        <div class="card h-100">
            <div class="card-body">
                <PromotionForm :modal-id="formModalId" :initial-active-tab="2" :id="promotion?.id" @onSuccess="onSuccess"></PromotionForm>
                <DiscountCodeCustomersShow :modal-id="showDiscountCodeCustomerModalId" :discount-code="showSelectedDiscountCodeItem"></DiscountCodeCustomersShow>
                <CustomTable
                    ref="promotionDiscountCodesTable"
                    :url="tableUrl"
                    :fields="table.fields"
                    :formatted-fields="table.formatted_fields"
                    :formatter="formatter"
                    :params="table.params"
                    :show-view="false"
                    :show-edit="false"
                    :filters2="table.filters"
                    :showSoftDelete="false"
                >
                    <template v-slot:header-actions>
                        <AddButton @click="showFormModal" label="Agregar códigos"></AddButton>
                    </template>

                    <template v-slot:filters="{ filters }">
                        <div class="row" v-if="!filters?.card_application_custom_filter_id">
                            <div class="form-group col-12 col-lg-3 col-sm-6">
                                <label for="code">Código</label>
                                <input id="code" v-model="filters.code" type="text" class="form-control"
                                    placeholder="Filtrar por código" />
                            </div>
                            <div class="form-group col-12 col-lg-3 col-sm-6">
                                <label for="promotion_modality_id">Modalidad de promoción</label>
                                <SelectField label="title" placeholder="Todos"
                                    :options="promotion?.promotion_modalities" v-model="filters.promotion_modality_id"></SelectField>                                        
                            </div>
                            <div class="form-group col-12 col-lg-3 col-sm-6">
                                <label for="promotion_modality_id">Utilizado</label>
                                <SelectField placeholder="Todos"
                                    :options="usedStatus" v-model="filters.used"></SelectField>                                        
                            </div>
                            <div class="form-group col-12 col-lg-3 col-sm-6">
                                <label for="phone">Fecha de creación</label>
                                <date-range-picker class="qlk-date-range-picker" :auto-apply="true"
                                    ref="created_at_range_picker"
                                    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                                    v-model="filters.created_at_range" :showDropdowns="true" :opens="'right'">
                                </date-range-picker>
                            </div>
                        </div>
                    </template>

                    <template #cell-customers_count="{ item }">
                        <a href="javascript:void(0);" class="mr-1 text-primary" v-b-tooltip.hover title="Ver detalle"
                            v-if="item?.customers_count > 0" @click="showViewCustomerModal(item)">
                            <i class="ri-eye-fill font-size-18"></i>
                        </a>
                        <span>{{ item?.customers_count }}</span>
                    </template>
                </CustomTable>
            </div>
        </div>
    </Modal> 
</template>