<script>
import { required } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';
import Status from "@/helpers/general/status";
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";

const initialState = () => {
    return {
        submitted: false,
        collaborator: {
            user_id: null,
            population_id: null,
            business_name: null,
            identification: null,
            contact_person: null,
            role: null,
            address: null,
            postcode: null,
            email: null,
            phone: null,
            mobile: null,
            status: 'pending',
            user: {
                name: null,
                email: null
            }
        },
        collaborator_status: Status.getList('collaborator_status'),
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        SelectAutocompleteField,
    },
    computed: {
    },
    validations: {
        collaborator: {
            user_id: { },
            population: { },
            business_name: { required },
            identification: { required },
            contact_person: { required },
            role: { },
            address: { },
            postcode: { },
            email: { },
            phone: { },
            mobile: { },
            status: { },
            user: {
                name: { },
                email: { }
            }
        }
    },
    methods: {
        async shown() {
            this.$store.dispatch("identificationType/loadList");

            if (this.id) {
                const response = await api.get({
                    url: `collaborators/${this.id}`,
                    config: {
                        withLoading: true
                    }  
                });

                const collaborator = response?.data?.data;
                this.collaborator = this.getCollaboratorWrapper(collaborator);
            }
        },
        getCollaboratorWrapper(collaborator) {
            if(!collaborator?.user) {
                collaborator.user = initialState().collaborator.user;
            }
            return collaborator;
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const data = this.collaborator;
            data.population_id = this.collaborator?.population?.id;
            const response = await api.save({
                url: 'collaborators',
                id: this.collaborator?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess", response?.data?.data);
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de colaborador'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
        <div class="row">

            <div class="col-lg-4 col-md-12 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">Datos básicos</h4>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="business_name">Razón social</label>
                                <input id="business_name" v-model="collaborator.business_name" type="text" class="form-control" placeholder="Ingresa una razón social"
                                    :class="{ 'is-invalid': $v.collaborator.business_name.$error && submitted }" />
                                <div v-if="!$v.collaborator.business_name.required && submitted" class="invalid-feedback">Razón social es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="identification">Identificación(Nif/Cif)</label>
                                <input id="identification" v-model="collaborator.identification" type="text" class="form-control"
                                    :class="{ 'is-invalid': $v.collaborator.identification.$error && submitted }" />
                                <div v-if="!$v.collaborator.identification.required && submitted" class="invalid-feedback">Identificación(Nif/Cif) es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="contact_person">Persona de contacto</label>
                                <input id="contact_person" v-model="collaborator.contact_person" type="text" class="form-control" placeholder="Ingresa una persona de contacto"
                                    :class="{ 'is-invalid': $v.collaborator.contact_person.$error && submitted }" />
                                <div v-if="!$v.collaborator.contact_person.required && submitted" class="invalid-feedback">Persona de contacto es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="role">Rol de la persona de contacto</label>
                                <input id="role" v-model="collaborator.role" type="text" class="form-control" placeholder="Ingresa un rol de la persona"
                                    :class="{ 'is-invalid': $v.collaborator.role.$error && submitted }" />
                                <div v-if="!$v.collaborator.role.required && submitted" class="invalid-feedback">Rol de la persona es requerido</div>
                            </div>
                            <div class="form-group col-12" v-has-permission="{ permission: 'collaborator.approve', action: 'remove' }">
                                <label for="status">Estado</label>
                                <select v-model="collaborator.status" class="form-control"
                                    :class="{ 'is-invalid': $v.collaborator.status.$error && submitted }">
                                    <option v-for="option in collaborator_status" v-bind:value="option.id" :key="option.id">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                        </div>                         
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-6 col-xs-12 mb-2">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            Datos de dirección y contacto
                        </h4>
                        <div class="row">
                            <div class="form-group col-lg-12">
                                <label>Población</label>
                                <SelectAutocompleteField
                                    :url="`/populations`"
                                    :multiple="false"
                                    :placeholder="'Busca una población'"
                                    :label="'fullname'"
                                    v-model="collaborator.population"
                                    :class="{ 'is-invalid': $v.collaborator.population.$error && submitted }"
                                ></SelectAutocompleteField>
                                <div v-if="!$v.collaborator.population.required && submitted" class="invalid-feedback">Población es requerida</div>
                            </div>
                            <div class="form-group col-lg-4 col-md-6">
                                <label for="address">Dirección</label>
                                <input id="address" v-model="collaborator.address" type="text" class="form-control" placeholder="Ingresa una dirección"
                                    :class="{ 'is-invalid': $v.collaborator.address.$error && submitted }" />
                                <div v-if="!$v.collaborator.address.required && submitted" class="invalid-feedback">Dirección es requerida</div>
                            </div>
                            <div class="form-group col-lg-4 col-md-6">
                                <label for="postcode">Código postal</label>
                                <input id="postcode" v-model="collaborator.postcode" type="text" class="form-control" placeholder="Ingresa un código postal"
                                    :class="{ 'is-invalid': $v.collaborator.postcode.$error && submitted }" />
                                <div v-if="!$v.collaborator.postcode.required && submitted" class="invalid-feedback">Código postal es requerido</div>
                            </div>
                            <div class="form-group col-lg-4 col-md-6">
                                <label for="mobile">Teléfono Móvil</label>
                                <input id="mobile" v-model="collaborator.mobile" type="text" class="form-control" placeholder="Ingresa un teléfono móvil"
                                    :class="{ 'is-invalid': $v.collaborator.mobile.$error && submitted }" />
                                <div v-if="!$v.collaborator.mobile.required && submitted" class="invalid-feedback">Teléfono Móvil es requerido</div>
                            </div>
                            <div class="form-group col-lg-4 col-md-6">
                                <label for="phone">Teléfono</label>
                                <input id="phone" v-model="collaborator.phone" type="text" class="form-control" placeholder="Ingresa un teléfono"
                                    :class="{ 'is-invalid': $v.collaborator.phone.$error && submitted }" />
                                <div v-if="!$v.collaborator.phone.required && submitted" class="invalid-feedback">Teléfono es requerido</div>
                            </div>
                            <div class="form-group col-lg-4 col-md-6">
                                <label for="email">Email de la empresa</label>
                                <input id="email" v-model="collaborator.email" type="text" class="form-control" placeholder="Ingresa un email"
                                    :class="{ 'is-invalid': $v.collaborator.email.$error && submitted }" />
                                <div v-if="!$v.collaborator.email.required && submitted" class="invalid-feedback">Email es requerido</div>
                            </div>
                        </div>                    
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            Datos de inicio de sesión
                        </h4>
                        <div class="row">
                            <div class="form-group col-lg-6 col-md-6">
                                <label for="user-name">Nombre</label>
                                <input id="user-name" v-model="collaborator.user.name" type="text" class="form-control" placeholder="Ingresa un nombre"
                                    :class="{ 'is-invalid': $v.collaborator.user.name.$error && submitted }" />
                                <div v-if="!$v.collaborator.user.name.required && submitted" class="invalid-feedback">Nombre es requerido</div>
                            </div>
                            <div class="form-group col-lg-6 col-md-6">
                                <label for="user-email">Email de acceso</label>
                                <input id="user-email" v-model="collaborator.user.email" type="text" class="form-control" placeholder="Ingresa un email"
                                    :class="{ 'is-invalid': $v.collaborator.email.$error && submitted }" />
                                <div v-if="!$v.collaborator.email.required && submitted" class="invalid-feedback">Email es requerido</div>
                            </div>
                        </div>                   
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>