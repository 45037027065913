<script>
import CustomTable from "@/components/tables/custom-table.vue";
import AdvantageForm from "@/views/pages/collaborators/forms/advantage/advantage-form.vue";
import AddButton from "@/components/buttons/add-button.vue";
import Status from "@/helpers/general/status";
import FeaturedIcon from '@/components/widgets/featured-icon.vue';

/**
 * Collaborators Component
 */
export default {
    props: {
        collaborator: Object
    },
    emits: ['onSuccess'],
    components: {
        CustomTable,
        AdvantageForm,
        AddButton,
        FeaturedIcon
    },
    data() {
        return {
            advantageFormId: 'advantage-form-modal',
            table: {
                params: {
                    'with_trashed': 1,
                    'status': 'all'
                },
                fields: [
                    { key: "id", label: "Cod", sortable: true },
                    { key: "created_at", label: "Fecha de creación", sortable: true },
                    { key: "name", label: "Nombre", sortable: true },
                    { key: "english_name", label: "Nombre en inglés", sortable: true },
                    { key: "product_category.name", label: "Categoría", sortable: false },
                    { key: "status", label: "Estado", sortable: false },
                    { key: "featured", label: "Destacada", sortable: false },
                ],
                formatted_fields: [
                    'status',
                    'featured',
                ]
            },
            formSelectedItemId: null,
        };
    },
    computed: {
        tableUrl() {
            return `/collaborators/${this.collaborator?.id}/advantages`;
        }
    },
    methods: {
        showFormModal(item) {
            this.formSelectedItemId = item?.id || null;
            this.$bvModal.show(this.advantageFormId);
        },
        onSuccess(response) {
            this.$refs.collaboratorAdvantageTable.refresh();
            this.$emit("onSuccess", response?.data?.data);
        },
        formatter(field, item) {
            switch (field) {
                case 'status':
                    return Status.getStatus({ itemStatus: item?.status, type: 'advantage_status', html: true });                
                default:
                    return '';
            }
        },
    }
};
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <AdvantageForm :modal-id="advantageFormId"
                        :collaborator="collaborator" :id="formSelectedItemId"
                        @onSuccess="onSuccess"></AdvantageForm>

                    <CustomTable
                        v-if="collaborator"
                        :url="tableUrl"
                        :fields="table.fields"
                        :formatted-fields="table.formatted_fields"
                        :formatter="formatter"
                        :params="table.params"
                        ref="collaboratorAdvantageTable"
                        :show-view="false"
                        :show-edit="true"
                        @edit="showFormModal"
                        :showSoftDelete="false"
                    >
                        <template v-slot:header-actions>
                            <AddButton @click="showFormModal"></AddButton>
                        </template>
                        <template #cell-featured="{ item }">
                            <featured-icon :featured="item?.featured"></featured-icon>
                        </template>
                    </CustomTable>
                </div>
            </div>
        </div>
    </div>
</template>