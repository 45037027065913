<script>
import Status from "@/helpers/general/status";
/**
 * Customers Component
 */
export default {
    props: {
      customer: {
            type: Object,
            default: () => {}
        },
    },
    components: {
    },
    data() {
        return {
        };
    },
    computed: {
        gender(){
            return (this?.customer?.gender) ? Status.getStatus({itemStatus: this?.customer?.gender, type: 'genders'}) : '';
        },
    },
    mounted(){
    },
    methods: {
    }
};
</script>

<template>
  <div class="row">
    <div class="col-6">
      <b-skeleton-wrapper :loading="$store.state.loading.loading">
        <template #loading>
            <b-skeleton-table
                :rows="5"
                :columns="2"
                :hide-header="true"
                :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table> 
        </template>
        <table class="table table-borderless table-hover mb-0 table-centered">
            <tbody>     
                <tr>
                    <th class="text-right nowrap">Móvil</th>
                    <td>{{ customer?.billings[0]?.phone }}</td>
                </tr>
                <tr>
                    <th class="text-right nowrap">Teléfono</th>
                    <td>{{ customer?.billings[0]?.phone_2 }}</td>
                </tr>             
                <tr>
                    <th class="text-right nowrap">Correo electrónico</th>
                    <td>{{ customer?.user?.email }}</td>
                </tr>
                <tr>
                    <th class="text-right nowrap">Nacimiento</th>
                    <td>{{ customer?.birthdate_formatted }}</td>
                </tr>
                <tr>
                    <th class="text-right nowrap">Género</th>
                    <td>{{ gender }}</td>
                </tr>
            </tbody>
        </table>
      </b-skeleton-wrapper>
    </div>
    <div class="col-6">
      <b-skeleton-wrapper :loading="$store.state.loading.loading">
        <template #loading>
            <b-skeleton-table
                :rows="5"
                :columns="2"
                :hide-header="true"
                :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table> 
        </template>
        <table class="table table-borderless table-hover mb-0 table-centered">
            <tbody>
                <tr>
                    <th class="text-right nowrap">Dirección</th>
                    <td>{{ customer?.billings[0]?.address_1 }}</td>
                </tr>
                <tr>
                    <th class="text-right nowrap">Cod. Postal</th>
                    <td>{{ customer?.billings[0]?.postcode }}</td>
                </tr>
                <tr>
                    <th class="text-right nowrap">Población</th>
                    <td>{{ customer?.billings[0]?.population?.name }}</td>
                </tr>
                <tr>
                    <th class="text-right nowrap">Comarca</th>
                    <td>{{ customer?.billings[0]?.population?.region?.name }}</td>
                </tr>
                <tr>
                    <th class="text-right nowrap">Provincia</th>
                    <td>{{ customer?.billings[0]?.population?.region?.province?.name }}</td>
                </tr>
            </tbody>
        </table>
      </b-skeleton-wrapper>
    </div>
  </div>
</template>