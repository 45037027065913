<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import CollaboratorShow from "@/views/pages/collaborators/profile/show/show"

export default {
  components: {
    Layout,
    PageHeader,
    CollaboratorShow
  },
  data() {
    return {
      title: "Inicio",
    };
  },
  computed:{
    isCollaborator() {
      return this.$store.getters['permission/isCollaborator']();
    },
  },
  methods: {
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div v-if="isCollaborator">
      <CollaboratorShow></CollaboratorShow>
    </div>
  </Layout>
</template>