<script>
import CustomTable from "@/components/tables/custom-table.vue";
import EstablishmentForm from "@/views/pages/collaborators/forms/establishment-form.vue";
import AddButton from "@/components/buttons/add-button.vue";

/**
 * Collaborators Component
 */
export default {
    props: {
        collaborator: Object
    },
    emits: ['onSuccess'],
    components: {
        CustomTable,
        EstablishmentForm,
        AddButton
    },
    data() {
        return {
            establishmentFormId: 'establishment-form-modal',
            table: {
                params: {
                    'with_trashed': 1,
                },
                fields: [
                    { key: "id", label: "Cod", sortable: false },
                    { key: "trade_name", label: "Nombre", sortable: false },
                    { key: "address", label: "Dirección", sortable: false },
                    { key: "postcode", label: "Cod. Postal", sortable: false },
                    { key: "phone", label: "Teléfono", sortable: false },
                    { key: "email", label: "Email", sortable: false },
                    { key: "website", label: "Sitio web", sortable: false },
                    { key: "facebook_url", label: "Facebook", sortable: false },
                    { key: "instagram_url", label: "Instagram", sortable: false },
                ],
                formatted_fields: [
                    'trade_name',
                    'address',
                    'email',
                    'website',
                    'facebook_url',
                    'instagram_url',
                ]
            },
            formSelectedItemId: null,
        };
    },
    computed: {
        tableUrl() {
            return `/collaborators/${this.collaborator?.id}/establishments`;
        }
    },
    methods: {
        showFormModal(item) {
            this.formSelectedItemId = item?.id || null;
            this.$bvModal.show(this.establishmentFormId);
        },
        onSuccess(response) {
            this.$refs.collaboratorEstablishmentTable.refresh();
            this.$emit("onSuccess", response?.data?.data);
        },
    }
};
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <EstablishmentForm :modal-id="establishmentFormId"
                        :collaborator="collaborator" :id="formSelectedItemId"
                        @onSuccess="onSuccess"></EstablishmentForm>

                    <CustomTable
                        v-if="collaborator"
                        :url="tableUrl"
                        :fields="table.fields"
                        :formatted-fields="table.formatted_fields"
                        :params="table.params"
                        ref="collaboratorEstablishmentTable"
                        :show-view="false"
                        :show-edit="true"
                        @edit="showFormModal"
                        :showSoftDelete="false"
                    >
                        <template v-slot:header-actions>
                            <AddButton @click="showFormModal"></AddButton>
                        </template>
                        <template #cell-trade_name="{ item }">
                            <span :title="item?.trade_name">{{ item?.trade_name | truncateText(20) }}</span>
                        </template>
                        <template #cell-address="{ item }">
                            <span :title="item?.address">{{ item?.address | truncateText(20) }}</span>
                        </template>
                        <template #cell-email="{ item }">
                            <span :title="item?.email">{{ item?.email | truncateText(20) }}</span>
                        </template>
                        <template #cell-website="{ item }">
                            <a :href="item?.website" :title="item?.website" target="_blank">{{ item?.website | truncateText(20) }}</a>
                        </template>
                        <template #cell-facebook_url="{ item }">
                            <a :href="item?.facebook_url" :title="item?.facebook_url" target="_blank">{{ item?.facebook_url | truncateText(20) }}</a>
                        </template>
                        <template #cell-instagram_url="{ item }">
                            <a :href="item?.instagram_url" :title="item?.instagram_url" target="_blank">{{ item?.instagram_url | truncateText(20) }}</a>
                        </template>
                    </CustomTable>
                </div>
            </div>
        </div>
    </div>
</template>