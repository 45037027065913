<script>
import api from '@/helpers/apirest/api';
import Vue from "vue";
export default {
  props: {
    cardApplication: {
      type: Object,
      default: () => {},
    },
  },
  emits:['onCreate', 'print'],
  data() {
    return {
      type: 'youth_card',
    };
  },
  methods: {
    async revised(item) {
      const result = await Vue.swal({
          title: "Revisar solicitud",
          icon: "info",
          html: `Está seguro que desea marcar como revisada esta solicitud?`,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar',
      });

      if (result.value) {
          await api.post({
              url: `card-applications/${item?.id}/revised`,
              config: {
                  withLoading: true,
              },
          });

          this.$emit('onCreate', this.type, item);
      }
    },
  }
};
</script>

<template>
  <div>
    <a v-if="!cardApplication?.revised" href="javascript:void(0);"
        class="mr-3 text-secondary" v-b-tooltip.hover title="Revisar solicitud"
        @click="revised(cardApplication)">
        <i class="mdi mdi-check-circle-outline font-size-18"></i>
    </a>
    <a v-else href="javascript:void(0);"
        class="mr-3 text-success" v-b-tooltip.hover title="Solicitud revisada">
        <i class="mdi mdi-check-circle font-size-18"></i>
    </a>
  </div>
</template>