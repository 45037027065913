<script>
import Modal from "@/components/modals/modal-form.vue";

import Prism from 'prismjs';
import 'prismjs/components/prism-json';  // Importa el soporte para JSON
import 'prismjs/themes/prism.css';       // Tema para el resaltado

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        orderIntegration: Object
    },
    data() {
        return {
        }
    },
    components: {
        Modal
    },
    mounted() {
        
    },
    computed: {
        modalTilte(){
            return `Detalle de respuesta (${this.orderIntegration?.integration?.name || ''})`;
        },
    },
    methods: {
        highlightCode() {
            Prism.highlightElement(this.$refs.data);
        },
        shown() {
            this.highlightCode();                    
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        }
    }
};
</script>

<template>
    <div>
        <Modal :title="modalTilte" :id="modalId" @cancel="cancel" @shown="shown" :size="'extra-xl'" :showConfirmButton="false" :label-cancel-btn="'Close'">
            <div class="row mb-4">
                <div class="col-md-12">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="card-title mb-4">Request response</h4>
                            <pre :class="`language-json`">
                                <code ref="data">
                                    {{ orderIntegration?.data }}
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>    
</template>