<script>
export default {
  props: {
    featured: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
    <i class="mdi mr-1 font-size-18" :class="featured ? 'mdi-star text-primary' : 'mdi-star-outline text-muted'" :title="featured ? 'Destacado' : 'No destacado'" v-b-tooltip.hover></i>
</template>