<script>
import Status from "@/helpers/general/status";
import InfoTooltip from "@/components/widgets/info-tooltip.vue";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            promotion_modality_types: Status.getList('promotion_modality_types'),
            discount_code_types: Status.getList('discount_code_types'),
        }
    },
    components: {   
        InfoTooltip,
    },
    methods: {
       
    },
};
</script>

<template>
    <div>
        <div class="row">
            <div class="form-group col-4">
                <label for="allow_discount_code">Maneja códigos de descuento?</label>
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="allow_discount_code"
                        v-model="value.allow_discount_code"
                    />
                    <label class="custom-control-label" for="allow_discount_code">Sí</label>
                </div>
            </div>    
            <div class="form-group col-4" v-if="value.allow_discount_code">
                <label for="discount_code_type">Uso
                    <InfoTooltip class="ml-2 float-right" title="Único: Un solo código que puede descargar cualquier titular. Por titular: Códigos por titular independientemente de la modalidad que elija. Por titular por modalidad: Códigos por titular por cada modalidad que elija."/>
                </label>
                <select v-model="value.discount_code_type" class="form-control"
                    :class="{ 'is-invalid': v.promotion.discount_code_type.$error && submitted }">
                    <option value="">-- Seleccione --</option>
                    <option v-for="option in discount_code_types" v-bind:value="option.id" :key="option.id">
                        {{ option.label }}
                    </option>
                </select>                  
            </div>    
            <div class="form-group col-4" v-if="value.allow_discount_code && value.discount_code_type != 'unique'">
                <label for="usage_limit_per_user">Límite por titular
                    <InfoTooltip class="ml-2 float-right" title="Ingresa la cantidad máxima de códigos de descuento que puede descargar cada titular"/>
                </label>
                <input id="usage_limit_per_user" v-model="value.usage_limit_per_user" type="text" class="form-control" placeholder="Ingresa un número"
                    :class="{ 'is-invalid': v.promotion.usage_limit_per_user.$error && submitted }" />
                <div v-if="!v.promotion.usage_limit_per_user.required && submitted" class="invalid-feedback">Indica el uso limite por titular</div>                
            </div>    
            <div class="form-group col-12" v-if="value.allow_discount_code && value.discount_code_type == 'unique'">
                <p class="card-text text-muted" >Ingresa el código que será válido para todos los titulares.</p>
                <label for="unique_discount_code">Código</label>
                <input id="unique_discount_code" v-model="value.unique_discount_code" type="text" class="form-control" placeholder="Ingresa un código"
                    :class="{ 'is-invalid': v.promotion.unique_discount_code.$error && submitted }" />
                <div v-if="!v.promotion.unique_discount_code.required && submitted" class="invalid-feedback">Código es requerido</div>                
            </div>    
        </div>

        <b-card header-class="bg-transparent border-secondary" class="border border-secondary" v-if="value.allow_discount_code && value.discount_code_type == 'per_holder'">
            <template v-slot:header>
                <h5 class="my-0 text-secondary">
                    <i class="mdi mdi-brightness-percent mr-2"></i>Lista de códigos
                </h5>
            </template>
            <p
                class="card-text"
            >Ingresa los códigos de descuento para toda la promoción</p>
            <div>
                <textarea class="form-control" v-model="value.discount_codes" rows="12" placeholder="Un código por linea..."
                    :class="{ 'is-invalid': v.promotion.discount_codes.$error && submitted }" 
                ></textarea>
            </div>
        </b-card>

        <div class="row" v-if="value.allow_discount_code && value.discount_code_type == 'per_modality'">
            <div class="col-6" v-for="(modality, key) in value.promotion_modalities" :key="key">
                <b-card header-class="bg-transparent border-secondary" class="border border-secondary">
                    <template v-slot:header>
                        <h5 class="my-0 text-secondary">
                            <i class="mdi mdi-brightness-percent mr-2"></i><span :title="modality.title">{{modality.title | truncateText(30)}}</span>
                        </h5>
                    </template>
                    <p
                        class="card-text"
                    >Ingresa los códigos de descuento para esta modalidad</p>
                    <div>
                        <textarea class="form-control" v-model="modality.discount_codes" rows="8" placeholder="Un código por linea..."
                            :class="{ 'is-invalid': v.promotion.discount_codes.$error && submitted }" ></textarea>
                    </div>
                </b-card>      
            </div>
            <b-alert
                show
                variant="secondary"
                class="text-center col-12"
                v-if="!value.promotion_modalities.length"
            >
                <p class="text-danger">Añade modalidades, para ingresar códigos de descuento</p>
            </b-alert>
        </div>
    </div>
</template>