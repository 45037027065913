<script>
import { required } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';

import BasicDataPartial from "./partials/basic-data-partial.vue";
import DescriptionPartial from './partials/description-partial.vue';
import TermAndConditionPartial from "./partials/term-and-condition-partial.vue";
import FooterPartial from "./partials/footer-partial.vue";
import ModalityPartial from "./partials/modality-partial.vue";
import LocationsPartial from "./partials/locations-partial.vue";
import DiscountCodePartial from "./partials/discount-code-partial.vue";

const initialState = () => {
    return {
        submitted: false,
        promotion: {
            id: null,
            header_media_id: null,
            header_media: null,
            feature_media_id: null,
            feature_media: null,
            collaborator: null,
            collaborator_id: null,
            creator_id: null,
            product_category: null,
            product_category_id: null,
            title: null,
            slug: null,
            full_description: null,
            terms_and_conditions: null,
            footer: null,
            status: 'pending',
            featured: false,
            allow_discount_code: false,
            discount_code_type: '',
            usage_limit_per_user: 1,
            unique_discount_code: '',
            discount_codes: '',
            promotion_modalities:[],
            promotion_locations:[{
                id: null,
                address: null,
                lat: 0,
                lng: 0,
            }],
            tags:[],
        },
        activeTab:null
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number,
        collaboratorId: Number,
        initialActiveTab: Number,
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        BasicDataPartial,
        DescriptionPartial,
        TermAndConditionPartial,
        FooterPartial,
        ModalityPartial,
        LocationsPartial,
        DiscountCodePartial
    },
    computed: {
    },
    validations: {
        promotion: {
            header_media_id: { },
            header_media: { required },
            feature_media_id: {  },
            feature_media: { required },
            collaborator: { },
            product_category: { },
            type: { },
            title: { required },
            full_description: { required },
            terms_and_conditions: { },
            footer: { },
            status: { },
            slug: { },
            allow_discount_code: { },
            discount_code_type: { 
                required(value) {
                    return this.promotion.allow_discount_code ? !!value : true;
                }
            },
            usage_limit_per_user: { 
                required(value) {
                    return (this.promotion.allow_discount_code && this.promotion.discount_code_type !== 'unique') ? !!value : true;
                }
            },
            unique_discount_code: { },
            discount_codes: { },
            promotion_modalities: {
                required,
                $each: {
                    title: { required },
                    feature_media: { },
                    short_description: { required },
                    description: { },
                    start_date: {},
                    end_date: {},
                    type: {},
                    value_without_youth_card: {},
                    value_with_youth_card: {},
                    action_label: {},
                    action_url: {},
                    secondary_action_label: {},
                    secondary_action_url: {},
                }
            },
            promotion_locations:{
                $each: {
                    address: { },
                    lat: { },
                    lng: { },
                }
            }
        }
    },
    methods: {
        async shown() {

            if (this.id) {
                if(this.initialActiveTab){
                    this.activeTab = this.initialActiveTab;
                }
                const response = await api.get({
                    url: `promotions/${this.id}`,
                    config: {
                        withLoading: true
                    }  
                });

                const promotion = response?.data?.data;
                if(promotion?.promotion_locations?.length === 0){
                    promotion.promotion_locations = initialState()?.promotion?.promotion_locations;
                }
                if(promotion?.promotion_modalities?.length > 0){
                    promotion.promotion_modalities.forEach(modality => {
                        modality.discount_codes = null;
                    });
                }
                promotion.discount_codes = null;
                this.promotion = promotion;
            }else{
                this.promotion.collaborator_id = this.collaboratorId;
            }
        },
        getPromotionWrapper() {
            return {
                id: null,
                header_media_id: null,
                header_media: null,
                feature_media_id: null,
                feature_media: null,
                collaborator_id: null,
                creator_id: null,
                product_category_id: null,
                type: null,
                title: null,
                full_description: null,
                address: null,
                lat: null,
                lng: null,
                terms_and_conditions: null,
                footer: null,
                status: 'pending',
                promotion_modalities:[],
                tags:[],
            }
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            const data = { ...this.promotion };
            data.feature_media_id = this.promotion?.feature_media?.id;
            data.header_media_id = this.promotion?.header_media?.id;
            data.product_category_id = this.promotion?.product_category?.id;
            data.collaborator_id = this.promotion?.collaborator?.id;
            data.tags = data.tags?.map(item => item?.name)

            let order = 0;
            data.promotion_modalities = data.promotion_modalities.map(item => {
                item.feature_media_id = item?.feature_media?.id || null;
                item.order = order;
                order++;
                return item;
            });

            console.log(data);

            const response = await api.save({
                url: 'promotions',
                id: this.promotion?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess", response?.data?.data);
        },
        hasErrorsInTab(tab) {
            
            if(tab === 'description_tab'){
                const fields = ['full_description'];
                return fields.some(field => this.$v.promotion[field].$invalid);
            }
            
            if(tab === 'promotion_modalities_tab'){
                const fields = ['promotion_modalities', 'discount_code_type'];
                return fields.some(field => this.$v.promotion[field].$invalid);
            }
            
            if(tab === 'discount_code_tab'){
                const fields = ['discount_code_type', 'usage_limit_per_user', 'unique_discount_code', 'discount_codes'];
                return fields.some(field => this.$v.promotion[field].$invalid);
            }

            return false;
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de promoción'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
        <div class="row">

            <div class="col-lg-4 col-md-12 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">Datos básicos</h4>
                        <BasicDataPartial v-model="promotion" :v="$v" :submitted="submitted"></BasicDataPartial>                        
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-6 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <b-tabs v-model="activeTab" content-class="p-3 text-muted">
                            <b-tab class="border-0">
                                <template v-slot:title>
                                    <span :class="{ 'text-danger': submitted && hasErrorsInTab('description_tab') }">
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">
                                            <i class="ri-alert-line" v-if="submitted && hasErrorsInTab('description_tab')"></i>
                                            Descripción
                                        </span>
                                    </span>                                    
                                </template>
                                <b-card-text>
                                    <DescriptionPartial v-model="promotion" :v="$v" :submitted="submitted"></DescriptionPartial>  
                                </b-card-text>
                            </b-tab>                             
                            <b-tab class="border-0">
                                <template v-slot:title>
                                    <span :class="{ 'text-danger': submitted && hasErrorsInTab('promotion_modalities_tab') }">
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">
                                            <i class="ri-alert-line" v-if="submitted && hasErrorsInTab('promotion_modalities_tab')"></i>
                                            Modalidades
                                        </span>
                                    </span>                                   
                                </template>
                                <b-card-text>
                                    <ModalityPartial v-model="promotion.promotion_modalities" :v="$v" :submitted="submitted"></ModalityPartial>  
                                </b-card-text>
                            </b-tab>                             
                            <b-tab class="border-0">
                                <template v-slot:title>
                                    <span :class="{ 'text-danger': submitted && hasErrorsInTab('discount_code_tab') }">
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">
                                            <i class="ri-alert-line" v-if="submitted && hasErrorsInTab('discount_code_tab')"></i>
                                            Códigos de descuento
                                        </span>
                                    </span>                                   
                                </template>
                                <b-card-text>
                                    <DiscountCodePartial v-model="promotion" :v="$v" :submitted="submitted"></DiscountCodePartial>  
                                </b-card-text>
                            </b-tab>                             
                            <b-tab class="border-0">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">
                                        Ubicación
                                    </span>                                   
                                </template>
                                <b-card-text>
                                    <LocationsPartial v-model="promotion.promotion_locations" :v="$v" :submitted="submitted"></LocationsPartial>  
                                </b-card-text>
                            </b-tab>                             
                            <b-tab class="border-0">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">
                                        Pie de contenido
                                    </span>                                   
                                </template>
                                <b-card-text>
                                    <FooterPartial v-model="promotion" :v="$v" :submitted="submitted"></FooterPartial>  
                                </b-card-text>
                            </b-tab>                             
                            <b-tab class="border-0">
                                <template v-slot:title>
                                    <span class="d-inline-block d-sm-none">
                                        <i class="fas fa-home"></i>
                                    </span>
                                    <span class="d-none d-sm-inline-block">
                                        Términos y condiciones
                                    </span>                                   
                                </template>
                                <b-card-text>
                                    <TermAndConditionPartial v-model="promotion" :v="$v" :submitted="submitted"></TermAndConditionPartial>  
                                </b-card-text>
                            </b-tab>                             
                        </b-tabs> 
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>